import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'
import WillPhoto from '../images/will.jpg'

const NotFoundPage = () => {
  return <Layout initialNavVariant="light">
    <header style={{ paddingTop: '12.5rem', paddingBottom: '7rem' }}>
      <div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-auto text-center team-member">
              <div className="photo mx-auto mb-3 rounded-circle" style={{backgroundImage: `url(${WillPhoto})`}}></div>
              
              <p className="lead">Sorry, we couldn't find the page you were looking for, man!</p>

              <Link className="text-arrow-icon" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left ml-0 mr-1"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>Return Home</Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Layout>
}

export default NotFoundPage
